import {ArrowDownTrayIcon} from '@heroicons/react/24/outline';
import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {TaxFormYearsOutput} from '@zentact/api/src/trpc/routers/financeRouter';
import {useCallback, useState} from 'react';
import {useNotification} from '../../../';
import {IconSpinner} from '../../../icons/spinner';

type Props = {
  taxFormYearRow: TaxFormYearsOutput['rows'][0];
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
};

export const TaxFormActions = ({taxFormYearRow, trpc}: Props) => {
  const {showErrorNotification} = useNotification();
  const [isFileLoading, setIsFileLoading] = useState(false);
  const {i18n} = useLingui();

  const trpcContext = trpc.useUtils();

  const handleDocumentDownload = useCallback(async () => {
    setIsFileLoading(true);
    try {
      const response = await trpcContext.finance.downloadTaxForm.fetch({
        merchantAccountId: taxFormYearRow.merchantAccount.id,
        year: taxFormYearRow.year,
      });

      const {fileName, mimeType, content} = response;

      const blob = new Blob([Uint8Array.from(atob(content), c => c.charCodeAt(0))], {
        type: mimeType,
      });
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = fileName;
      link.click();
    } catch (e) {
      showErrorNotification(i18n._('Failed to download a file'), (e as Error).message);
    }
    setIsFileLoading(false);
  }, [taxFormYearRow, setIsFileLoading, showErrorNotification]);

  return (
    <div className="flex items-center justify-end">
      {!isFileLoading ? (
        <ArrowDownTrayIcon
          className="w-5 h-5 cursor-pointer text-primary-600"
          onClick={handleDocumentDownload}
        />
      ) : (
        <IconSpinner />
      )}
    </div>
  );
};
