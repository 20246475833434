import {useLingui} from '@lingui/react';
import {StoresListItem} from '@zentact/api/src/trpc/routers/storeRouter';
import {allIndustryGroups, formatLocaleDate} from '@zentact/common';
import {
  FlatPillWithDot,
  SlideOverWithBrandedHeader,
  getStoreLocalizedStatusMap,
  storeStatusToColor,
} from '@zentact/ui-tailwind';
import {Link, generatePath} from 'react-router-dom';

type Props = {
  isOpen: boolean;
  onCancel: () => void;
  row: StoresListItem | null;
  merchantDetailsPagePath?: string;
};

export const StoreDetailsSidePanel = ({isOpen, onCancel, row, merchantDetailsPagePath}: Props) => {
  const {i18n} = useLingui();

  if (!row) {
    return null;
  }

  const {businessName: merchantAccountName, id: merchantAccountId} = row.merchantAccount;

  const address = [
    row.businessAddressCity,
    row.businessAddressLine1,
    row.businessAddressLine2,
    row.businessAddressPostalCode,
  ]
    .filter(v => !!v)
    .join(' ');

  const industryCodes = Array.from(
    new Set(
      row.storeBusinessLines.map(b => b.businessLine.industryCode).filter((v): v is string => !!v)
    )
  );

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('View Store Details')}
      closeHandler={onCancel}
    >
      <div className="border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 pb-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Store Name')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {row.displayName}
            </dd>
          </div>
          {merchantAccountName && merchantDetailsPagePath && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                {i18n._('Merchant Account')}
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                <Link
                  to={generatePath(merchantDetailsPagePath, {
                    merchantAccountId: merchantAccountId,
                  })}
                  className="text-primary-600 hover:underline"
                >
                  {merchantAccountName}
                </Link>
              </dd>
            </div>
          )}
          {address.length > 0 && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Address')}</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {address}
              </dd>
            </div>
          )}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">
              {i18n._('Shopper Descriptor')}
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {row.shopperStatement || i18n._('N/A')}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Status')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {
                <FlatPillWithDot
                  color={storeStatusToColor[row.status] || 'blue'}
                  label={getStoreLocalizedStatusMap(i18n)[row.status] || row.status}
                />
              }
            </dd>
          </div>
          {industryCodes.length > 0 && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Industry')}</dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {industryCodes
                  .map(
                    industryCode =>
                      allIndustryGroups[industryCode as keyof typeof allIndustryGroups] ??
                      i18n._('Unrecognized')
                  )
                  .join(', ')}
              </dd>
            </div>
          )}
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Created At')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {formatLocaleDate(row.createdAt)}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">{i18n._('Last Active')}</dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {formatLocaleDate(row.updatedAt)}
            </dd>
          </div>
        </dl>
      </div>
    </SlideOverWithBrandedHeader>
  );
};
