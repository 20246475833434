import {I18n} from '@lingui/core';
import {Trans} from '@lingui/macro';
import {createColumnHelper} from '@tanstack/react-table';
import {StoresListItem, StoresListOutput} from '@zentact/api/src/trpc/routers/storeRouter';
import {TableColumnSize, formatLocaleDate} from '@zentact/common';
import {
  FlatPillWithDot,
  HighlightedText,
  TruncatedText,
  getStoreLocalizedStatusMap,
  getTableMeta,
  storeStatusToColor,
} from '@zentact/ui-tailwind';

const columnsHelper = createColumnHelper<StoresListOutput['rows'][0]>();

type Props = {
  openDetailsPanel?: (row: StoresListItem) => void;
  i18n: I18n;
};
export const getStoresListColumns = ({i18n}: Props) => {
  const merchantAccountColumn = columnsHelper.display({
    cell: props => <TruncatedText text={props.row.original.merchantAccount.businessName} />,
    id: 'businessName',
    header: () => <Trans>Merchant Account</Trans>,
    meta: {
      collapseAt: 'md',
    },
  });

  const storeNameColumn = columnsHelper.display({
    cell: props => {
      const {filterValues} = getTableMeta(props.table);
      const searchString = filterValues?.displayName as string;
      const textContent = props.row.original.displayName || i18n._('N/A');
      if (searchString) {
        return (
          <HighlightedText
            text={textContent}
            highlight={searchString}
            className="break-all text-wrap"
          />
        );
      }
      return <TruncatedText text={textContent} />;
    },
    id: 'displayName',
    header: () => <Trans>Store</Trans>,
  });

  const storeAddressColumn = columnsHelper.display({
    id: 'address',
    cell: props => {
      const {
        businessAddressCity,
        businessAddressLine1,
        businessAddressLine2,
        businessAddressPostalCode,
      } = props.row.original;

      const address = [
        businessAddressCity,
        businessAddressLine1,
        businessAddressLine2,
        businessAddressPostalCode,
      ]
        .filter(v => !!v)
        .join(' ');

      return <TruncatedText text={address || i18n._('N/A')} maxLength={25} />;
    },
    header: () => <Trans>Address</Trans>,
  });

  const shopperDescriptorColumn = columnsHelper.display({
    id: 'shopperDescriptor',
    cell: props => {
      return <TruncatedText text={props.row.original.shopperStatement || i18n._('N/A')} />;
    },
    header: () => <Trans>Shopper Descriptor</Trans>,
    meta: {
      visibleAt: 'lg',
    },
  });

  return [
    storeNameColumn,
    merchantAccountColumn,
    storeAddressColumn,
    shopperDescriptorColumn,
    columnsHelper.accessor(row => row.status, {
      id: 'status',
      cell: info => (
        <FlatPillWithDot
          color={storeStatusToColor[info.getValue()] || 'blue'}
          label={getStoreLocalizedStatusMap(i18n)[info.getValue()] || info.getValue()}
        />
      ),
      header: () => <Trans>Status</Trans>,
      size: TableColumnSize.XXS,
    }),
    columnsHelper.accessor('updatedAt', {
      cell: info => formatLocaleDate(info.getValue(), 'shortWithTime'),
      header: () => <Trans>Last Active</Trans>,
      meta: {
        sort: {
          isSortable: true,
        },
        collapseAt: 'sm',
      },
      size: TableColumnSize.S,
    }),
  ];
};
