import {trpc} from '@/api/trpcClient';
import {zodResolver} from '@hookform/resolvers/zod';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  MerchantAccountDetailsOutput,
  MerchantAccountsListItem,
} from '@zentact/api/src/trpc/routers/merchantAccountRouter';
import {isPossiblePhoneNumber} from '@zentact/common';
import {
  Button,
  InputPhone,
  InputText,
  Label,
  SlideOverWithBrandedHeader,
  ValidationError,
  useNotification,
} from '@zentact/ui-tailwind';
import {useCallback} from 'react';
import {useForm} from 'react-hook-form';
import {z} from 'zod';

export const getUpdateMerchantCustomerSupportSchema = () =>
  z.object({
    phoneNumber: z
      .string()
      .nullable()
      .transform(phoneNumber => phoneNumber ?? '')
      .refine(phoneNumber => isPossiblePhoneNumber(phoneNumber), {
        message: t`Phone number is invalid`,
      }),
    supportEmail: z
      .string()
      .min(1, t`Please enter a support email address`)
      .email(t`Please enter a valid email address`)
      .max(50, t`Email address must not exceed 50 characters`)
      .or(z.literal('')),
    businessAddressLine1: z.string().min(1, t`Street Address is required`),
    businessAddressLine2: z.string().optional(),
    businessAddressCity: z.string().min(1, t`City is required`),
    businessAddressState: z
      .string()
      .min(1, t`State is required`)
      .max(3, t`Please use two or three letter abbreviation for the state`),
    businessAddressPostalCode: z.string().min(1, t`Postal code is required`),
  });

export type UpdateMerchantCustomerSupportData = z.infer<
  ReturnType<typeof getUpdateMerchantCustomerSupportSchema>
>;

export type UpdateMerchantCustomerSupportProps = {
  merchantAccountRow: MerchantAccountDetailsOutput | MerchantAccountsListItem;
  onClose: () => void;
  isOpen: boolean;
  triggerRefetch: () => void;
};

export const UpdateMerchantCustomerSupport = ({
  merchantAccountRow,
  onClose,
  isOpen,
  triggerRefetch: refetchMerchantAccountsList,
}: UpdateMerchantCustomerSupportProps) => {
  const {i18n} = useLingui();
  const brandConfiguration =
    'brandConfiguration' in merchantAccountRow
      ? merchantAccountRow.brandConfiguration
      : merchantAccountRow.merchantAccount?.brandConfiguration;
  const merchantAccountId =
    'brandConfiguration' in merchantAccountRow
      ? merchantAccountRow.id
      : merchantAccountRow.merchantAccount?.id;

  const updateMerchantCustomerSupportForm = useForm<UpdateMerchantCustomerSupportData>({
    resolver: zodResolver(getUpdateMerchantCustomerSupportSchema()),
    defaultValues: {
      phoneNumber: brandConfiguration?.phoneNumber || '',
      supportEmail: brandConfiguration?.supportEmail ?? undefined,
      businessAddressLine1: brandConfiguration?.businessAddressLine1 ?? undefined,
      businessAddressLine2: brandConfiguration?.businessAddressLine2 ?? undefined,
      businessAddressCity: brandConfiguration?.businessAddressCity ?? undefined,
      businessAddressState: brandConfiguration?.businessAddressState ?? undefined,
      businessAddressPostalCode: brandConfiguration?.businessAddressPostalCode ?? undefined,
    },
  });
  const {
    handleSubmit,
    register,
    formState: {errors},
    control,
  } = updateMerchantCustomerSupportForm;
  const {showSuccessNotification, showErrorNotification} = useNotification();

  const updateMerchantMutation = trpc.merchantAccount.editMerchantAccountBranding.useMutation({
    onSuccess: () => {
      refetchMerchantAccountsList();
      onClose();
      showSuccessNotification(
        t`Merchant information updated!`,
        t`You have successfully updated merchant information.`
      );
    },
    onError: error => {
      showErrorNotification(t`Error`, error.message);
    },
  });

  const onUpdateMerchantSubmit = useCallback(
    (data: UpdateMerchantCustomerSupportData) => {
      if (!merchantAccountId) {
        return;
      }
      updateMerchantMutation.mutate({
        merchantAccountId: merchantAccountId,
        ...data,
      });
      return;
    },
    [merchantAccountId]
  );

  return (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      title={i18n._('Merchant Customer Support')}
      text={i18n._('The contact information provided below is displayed to customers.')}
      closeHandler={onClose}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <div className="flex shrink-0 gap-x-3">
            <Button
              variant="primary"
              size="lg"
              className="w-fit"
              onClick={handleSubmit(onUpdateMerchantSubmit)}
              isLoading={updateMerchantMutation.isLoading}
            >
              <Trans>Update Merchant</Trans>
            </Button>
          </div>
          <Button variant="secondary" size="lg" className="w-fit" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onUpdateMerchantSubmit)}>
        <div>
          <div className="flex flex-col gap-3 pb-6">
            <Label text={i18n._('Phone Number')}>
              <InputPhone
                name="phoneNumber"
                control={control}
                defaultCountry={brandConfiguration?.businessAddressCountry}
              />
              <ValidationError isVisible={Boolean(errors.phoneNumber)}>
                {errors.phoneNumber?.message}
              </ValidationError>
            </Label>

            <Label text={i18n._('Support Email')}>
              <InputText {...register('supportEmail')} placeholder="example@mail.com" />
              <ValidationError isVisible={Boolean(errors.supportEmail)}>
                {errors.supportEmail?.message}
              </ValidationError>
            </Label>

            <h2 className="text-base font-medium leading-6 text-gray-900">{i18n._('Address')}</h2>

            <div className="flex flex-col gap-3 pb-4">
              <div className="col-span-full">
                <Label text={i18n._('Street Address')}>
                  <InputText {...register('businessAddressLine1')} />
                  <ValidationError isVisible={Boolean(errors.businessAddressLine1)}>
                    {errors.businessAddressLine1?.message}
                  </ValidationError>
                </Label>
              </div>
              <div className="col-span-full">
                <Label text={i18n._('Line 2')}>
                  <InputText {...register('businessAddressLine2')} />
                  <ValidationError isVisible={Boolean(errors.businessAddressLine2)}>
                    {errors.businessAddressLine2?.message}
                  </ValidationError>
                </Label>
              </div>
              <div className="sm:col-span-2 sm:col-start-1">
                <Label text={i18n._('City')}>
                  <InputText {...register('businessAddressCity')} />
                  <ValidationError isVisible={Boolean(errors.businessAddressCity)}>
                    {errors.businessAddressCity?.message}
                  </ValidationError>
                </Label>
              </div>
              <div className="sm:col-span-2">
                <Label text={i18n._('State / Province')}>
                  <InputText {...register('businessAddressState')} />
                  <ValidationError isVisible={Boolean(errors.businessAddressState)}>
                    {errors.businessAddressState?.message}
                  </ValidationError>
                </Label>
              </div>
              <div className="sm:col-span-2">
                <Label text={i18n._('ZIP / Postal code')}>
                  <InputText {...register('businessAddressPostalCode')} />
                  <ValidationError isVisible={Boolean(errors.businessAddressPostalCode)}>
                    {errors.businessAddressPostalCode?.message}
                  </ValidationError>
                </Label>
              </div>
            </div>
          </div>
        </div>
      </form>
    </SlideOverWithBrandedHeader>
  );
};
