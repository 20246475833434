import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {
  Breadcrumbs,
  MerchantAccountsPicker,
  ResetTableFiltersButton,
  TableSortValue,
  TaxFormList,
  Typography,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useEffect, useState} from 'react';
import {z} from 'zod';

const getBreadCrumbs = () => [
  {name: t`Finance`, href: RoutePath.FINANCE, current: false},
  {name: t`Taxes`, href: RoutePath.TAXES, current: true},
];

const searchParamsSchema = z.object({
  selectedMerchantAccount: z.string().optional(),
  selectedOrganization: z.string().optional(),
});

export const Taxes = () => {
  const {activeAndInactiveMerchantAccounts: merchantAccounts} = useStore();

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(searchParamsSchema);

  const selectedMerchantAccount = typedSearchParams?.selectedMerchantAccount;

  const [sort, setSort] = useState<TableSortValue<string>>({columnId: 'date', value: 'desc'});
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const taxFormYears = trpc.finance.getTaxFormYears.useQuery(
    {
      ...pagination,
      ...(selectedMerchantAccount && {merchantAccountId: selectedMerchantAccount}),
    },
    {
      keepPreviousData: true,
    }
  );

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams, sort]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Tax Forms</Trans>
        </Typography>
        {!!merchantAccounts?.length && (
          <div className="flex gap-2 font-normal max-2xl:flex-wrap 2xl:items-center">
            <div className="max-sm:w-full">
              <ResetTableFiltersButton
                defaultFilters={{}}
                activeFilters={typedSearchParams}
                setFilters={setTypedSearchParams}
              />
            </div>
            <div className="mr-1 font-normal shrink-0 w-60">
              <MerchantAccountsPicker
                selectedMerchantAccount={selectedMerchantAccount}
                onSelectMerchantAccount={value =>
                  setTypedSearchParams({selectedMerchantAccount: value})
                }
                merchantAccountsOptions={merchantAccounts}
                allLabel={t`All Merchant Accounts`}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-4">
        <div className="mb-4 text-sm leading-6 text-gray-500">
          <Trans>
            In the US, payment processors are required to file a 1099-K tax form with the IRS for
            any US-based user to whom they pay out directly. Some states also require the filing of
            1099-K forms.
          </Trans>
        </div>
        <TaxFormList
          taxFormYears={taxFormYears.data}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          trpc={trpc}
          isLoading={
            taxFormYears.isLoading || (taxFormYears.isRefetching && taxFormYears.isPreviousData)
          }
        />
      </div>
    </div>
  );
};
