import {TopBar} from '@/components/top-bar';
import {useStore} from '@/store';
import {useAuth0} from '@auth0/auth0-react';
import {i18n} from '@lingui/core';
import {Trans} from '@lingui/macro';
import {ErrorCode} from '@zentact/common';
import {Loading, StepperHorizontalCircles, StepperVertical, Typography} from '@zentact/ui-tailwind';
import {useEffect, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {trpc} from '../../api/trpcClient';
import {MerchantApplication} from './components/merchant-application';
import {MerchantVerificationFailure} from './components/merchant-verification-failure';
import {MerchantVerificationPending} from './components/merchant-verification-pending';
import {RegistrationComplete} from './components/registration-complete';
import {TermsAndConditions} from './components/terms-and-conditions';
import {MERCHANT_REGISTRATION_STEP_IDS, merchantRegistrationSteps} from './constants';

export const MerchantRegistrationPage = ({
  registrationCompleteRedirectPath,
}: {
  registrationCompleteRedirectPath: string;
}) => {
  const {registrationSessionId} = useParams();
  const navigate = useNavigate();
  const {tenant} = useStore();
  const {user, isLoading: isUserLoading, loginWithRedirect} = useAuth0();
  const [shouldPoll, setShouldPoll] = useState<boolean>(false);

  const registrationStatusQuery = trpc.merchantRegistration.registrationStatus.useQuery(
    {
      // biome-ignore lint/style/noNonNullAssertion: TODO
      registrationSessionId: registrationSessionId!,
    },
    {
      refetchInterval: shouldPoll ? 1000 * 5 : false,
      refetchOnWindowFocus: true,
      enabled: !!registrationSessionId,
    }
  );

  if (!user) {
    loginWithRedirect({
      appState: {returnTo: window.location.pathname},
    });

    return null;
  }

  if (!registrationSessionId) {
    navigate('/error', {state: {errorCode: ErrorCode.MERCHANT_REGISTRATION_NOT_FOUND_FOR_USER}});
    return;
  }

  const stepId = useMemo(() => {
    switch (registrationStatusQuery.data?.status) {
      case 'INITIALIZED':
      case 'USER_ACCOUNT_CREATED':
        return MERCHANT_REGISTRATION_STEP_IDS.step2;
      case 'SIGNED_TERMS_AND_CONDITIONS':
        return MERCHANT_REGISTRATION_STEP_IDS.step3;
      case 'KYC_VERIFICATION_AWAITING_DATA':
      case 'KYC_VERIFICATION_PENDING':
      case 'KYC_VERIFICATION_FAILURE':
        return MERCHANT_REGISTRATION_STEP_IDS.step4;
      case 'BOARDED':
        return MERCHANT_REGISTRATION_STEP_IDS.step5;
      case undefined: {
        return null;
      }
    }
  }, [registrationStatusQuery.data, user]);

  useEffect(() => {
    if (registrationStatusQuery.error) {
      navigate('/error', {state: {errorCode: ErrorCode.MERCHANT_REGISTRATION_NOT_FOUND_FOR_USER}});
    }
    if (registrationStatusQuery.data?.revoked) {
      navigate('/error', {state: {errorCode: ErrorCode.MERCHANT_REGISTRATION_REVOKED}});
    }
  }, [registrationStatusQuery]);

  useEffect(() => {
    if (
      stepId === MERCHANT_REGISTRATION_STEP_IDS.step4 ||
      stepId === MERCHANT_REGISTRATION_STEP_IDS.step5
    ) {
      if (!shouldPoll) {
        setShouldPoll(true);
      }
    } else {
      if (shouldPoll) {
        setShouldPoll(false);
      }
    }
  }, [stepId, shouldPoll]);

  const currentStepIndex = useMemo(
    () => merchantRegistrationSteps.findIndex(item => item.id === stepId),
    [
      stepId,
      registrationStatusQuery.isLoading,
      registrationStatusQuery.data?.registrationSource,
      tenant?.name,
    ]
  );

  const stepContent = useMemo(() => {
    switch (registrationStatusQuery.data?.status) {
      case 'INITIALIZED':
      case 'USER_ACCOUNT_CREATED':
        return (
          <TermsAndConditions
            registrationSessionId={registrationSessionId}
            onComplete={() => registrationStatusQuery.refetch()}
          />
        );
      case 'SIGNED_TERMS_AND_CONDITIONS':
        return (
          <MerchantApplication
            uploadedDocumentName={registrationStatusQuery.data.uploadedDocumentName}
            registrationSessionId={registrationSessionId}
            refetchRegistrationStatus={registrationStatusQuery.refetch}
            onComplete={redirectUrl => {
              registrationStatusQuery.refetch();
              window.location.href = redirectUrl;
            }}
          />
        );
      case 'KYC_VERIFICATION_PENDING':
      case 'KYC_VERIFICATION_AWAITING_DATA':
        return (
          <MerchantVerificationPending
            registrationSessionId={registrationSessionId}
            onBackToApplication={redirectUrl => {
              window.location.href = redirectUrl;
            }}
          />
        );
      case 'KYC_VERIFICATION_FAILURE':
        return (
          <MerchantVerificationFailure
            registrationSessionId={registrationSessionId}
            onBackToApplication={redirectUrl => {
              window.location.href = redirectUrl;
            }}
          />
        );
      case 'BOARDED': {
        return (
          <RegistrationComplete
            onComplete={() => {
              navigate(registrationCompleteRedirectPath);
            }}
          />
        );
      }
      case undefined: {
        return null;
      }
    }
  }, [registrationStatusQuery.data, user]);

  const merchantRegistrationStepsWithLang = merchantRegistrationSteps.map(el => ({
    ...el,
    title: i18n._(el.title as string),
  }));
  if (isUserLoading || registrationStatusQuery.isLoading) {
    return (
      <>
        <TopBar />
        <Loading />
      </>
    );
  }
  return (
    <div className="min-h-screen bg-gray-100">
      <TopBar />
      <div className="px-6 mx-auto max-w-7xl lg:px-8">
        <div className="space-y-10 divide-y divide-gray-900/10">
          <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
            <div className="flex flex-col gap-5 sm:px-0">
              <Typography variant="header-base" as="h3" className="md:py-5">
                <Trans>Merchant Sign Up</Trans>
              </Typography>
              <StepperVertical
                className="hidden md:block"
                steps={merchantRegistrationStepsWithLang}
                selectedIndex={currentStepIndex}
              />
              <StepperHorizontalCircles
                className="flex md:hidden"
                steps={merchantRegistrationStepsWithLang}
                selectedIndex={currentStepIndex}
              />
            </div>
            <div className="md:col-span-2 md:mt-5">{stepContent}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
