import {useLingui} from '@lingui/react';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {TaxFormYearsOutput} from '@zentact/api/src/trpc/routers/financeRouter';
import {Dispatch, SetStateAction} from 'react';
import {TableSortValue, TableWrapper} from '../../../table';
import {getTaxFormColumns} from './columns';

type Props = {
  taxFormYears?: TaxFormYearsOutput;
  isLoading?: boolean;
  sort: TableSortValue<string> | null;
  setSort: Dispatch<SetStateAction<TableSortValue<string>>>;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
  pagination?: {pageIndex: number; pageSize: number};
  onPaginationChange?: Dispatch<SetStateAction<{pageIndex: number; pageSize: number}>>;
};

export const TaxFormList = ({
  taxFormYears,
  sort,
  setSort,
  isLoading,
  trpc,
  pagination,
  onPaginationChange,
}: Props) => {
  const {i18n} = useLingui();
  return (
    <TableWrapper
      columns={getTaxFormColumns({i18n, trpc})}
      data={taxFormYears?.rows ?? []}
      sortValue={sort}
      onSortChange={setSort}
      noDataMessage={
        <div>
          <h3 className="text-sm font-semibold text-gray-900">
            {i18n._('No Tax Forms available')}
          </h3>
        </div>
      }
      pagination={pagination}
      onPaginationChange={onPaginationChange}
      pageCount={taxFormYears?.pagination?.pageCount ?? 0}
      isLoading={isLoading}
    />
  );
};
