import {trpc} from '@/api/trpcClient';
import {AdyenPlatformExperience, CapitalOverview} from '@adyen/adyen-platform-experience-web';
import '@adyen/adyen-platform-experience-web/adyen-platform-experience-web.css';
import {CapitalSessionResponse} from '@zentact/core';
import {Loading} from '@zentact/ui-tailwind';
import {useEffect, useRef, useState} from 'react';

type Props = {
  merchantAccountId?: string;
};

export const CapitalOverviewComponent = ({merchantAccountId}: Props) => {
  const containerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const createCapitalSession = trpc.capital.createAdyenCapitalSession.useMutation();

  const handleSessionCreate = async (
    merchantAccountId: string
  ): Promise<CapitalSessionResponse> => {
    return await createCapitalSession.mutateAsync({merchantAccountId});
  };

  useEffect(() => {
    let capitalOverview: CapitalOverview | undefined;
    setIsLoading(true);

    const initialize = async () => {
      if (merchantAccountId) {
        const core = await AdyenPlatformExperience({
          onSessionCreate: () => handleSessionCreate(merchantAccountId),
        });
        capitalOverview = new CapitalOverview({core});
        capitalOverview.mount('#capital-overview-container');
      } else {
        capitalOverview?.unmount();
      }
    };

    initialize();

    return () => {
      capitalOverview?.unmount();
    };
  }, [merchantAccountId]);

  // We don't control how the Adyen Capital Overview component renders.
  // Therefore, we must observe its container for any changes and display
  // the component only once it has fully rendered.
  const checkIsRenderingFinished = (records: MutationRecord[]) => {
    for (const record of records) {
      for (const node of Array.from(record.removedNodes)) {
        if (
          node instanceof HTMLElement &&
          node.className === 'adyen-pe-capital-overview__header-skeleton-container'
        ) {
          setIsLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(checkIsRenderingFinished);
    if (containerRef.current) {
      observer.observe(containerRef.current, {childList: true, subtree: true});
    }
    return () => {
      observer.disconnect();
    };
  }, [containerRef]);

  return (
    <>
      {isLoading && <Loading mode="inline" className="pt-14" />}
      <div
        id="capital-overview-container"
        className="gap-4 mx-auto mt-4"
        ref={containerRef}
        hidden={isLoading}
      />
    </>
  );
};
