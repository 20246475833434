import {Ach, AdyenCheckout} from '@adyen/adyen-web-checkout-beta';
import {GetCheckoutV2Output} from '@zentact/api/src/trpc/routers/checkoutRouter';
import {useCallback, useState} from 'react';

export const useAdyenAch = () => {
  const [achComponent, setAchComponent] = useState<Ach>();

  const initiateAdyenAchComponent = useCallback(
    async (domNode: HTMLElement, checkout: GetCheckoutV2Output) => {
      const configuration = {
        locale: checkout.locale,
        amount: {
          value: checkout.amount,
          currency: checkout.currency,
        },
        allowPaymentMethods: ['ach'],
        environment:
          (checkout.adyenConfiguration.environment as
            | 'test'
            | 'live'
            | 'live-us'
            | 'live-au'
            | 'live-apse'
            | 'live-in') || 'test',
        clientKey: checkout.adyenConfiguration.clientKey,
        countryCode: checkout.countryCode,
        showPayButton: false,
      };
      const adyenCheckout = await AdyenCheckout(configuration);

      const ach = new Ach(adyenCheckout, {
        type: 'ach',
        amount: {
          value: checkout.amount,
          currency: checkout.currency,
        },
        placeholders: {
          holderName: 'John Doe',
        },
        hasHolderName: true,
        holderNameRequired: false,
        // @ts-ignore do work but don't exist in the types
        billingAddressRequired: false,
      }).mount(domNode);
      ach.updateStyles({
        base: {
          fontSize: '16px',
          padding: '12px',
        },
        error: {
          color: 'red',
        },
        validated: {
          color: 'green',
        },
        placeholder: {
          color: '#9CA3AF',
          fontWeight: 'semibold',
        },
      });
      setAchComponent(ach);
    },
    [setAchComponent]
  );

  return {
    initiateAdyenAchComponent,
    achComponent,
  };
};

export type InitiateAdyenAchComponentFunction = ReturnType<
  typeof useAdyenAch
>['initiateAdyenAchComponent'];
