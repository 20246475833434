import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {
  OrganizationAccountActivityDetailsItem,
  TenantAccountActivityDetailsItem,
} from '@zentact/api/src/trpc/routers/financeRouter';
import {
  OrganizationCategoryArray,
  accountActivitySearchParamsSchema,
  formatLocaleDate,
} from '@zentact/common';
import {
  AccountActivityDetailsSidePanel,
  AccountActivityDetailsTable,
  ActivityDetailsFilters,
  Breadcrumbs,
  Button,
  ResetTableFiltersButton,
  SlideOverWithBrandedHeader,
  TableSortValue,
  Typography,
  fetchFullListFromPaginatedEndpoint,
  useNotification,
  useToggle,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {DateTime} from 'luxon';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {exportToCsvAccountActivityDetails} from './csv-export';

export const AccountActivityDetails = () => {
  const {activeAndInactiveMerchantAccounts: merchantAccounts} = useStore();

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(
    accountActivitySearchParamsSchema
  );

  const batchId = typedSearchParams?.batchId;
  const startDate = batchId
    ? null
    : typedSearchParams?.startDate || DateTime.now().startOf('month').toJSDate();
  const endDate = batchId
    ? null
    : typedSearchParams?.endDate || DateTime.now().endOf('month').toJSDate();
  const category = typedSearchParams?.category;
  const merchantAccountId = typedSearchParams?.merchantAccountId;
  const storeId = typedSearchParams?.storeId;

  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams]);

  const [isSidePanelOpen, openSidePanel, closeSidePanel] = useToggle(false);
  const [accountActivityDetailsRow, setAccountActivityDetailsRow] = useState<
    OrganizationAccountActivityDetailsItem | TenantAccountActivityDetailsItem | null
  >(null);

  const getBreadCrumbs = () => [
    {name: t`Finance`, href: RoutePath.FINANCE, current: false},
    {name: t`Account Activity`, href: RoutePath.FINANCE_ACCOUNT_ACTIVITY, current: true},
  ];

  const [sort, setSort] = useState<TableSortValue<string>>({
    columnId: 'creationDate',
    value: 'desc',
  });

  const accountActivityDetailsFilters = {
    ...(sort?.columnId && sort.value && {orderBy: {columnId: sort.columnId, value: sort.value}}),
    where: {
      ...(storeId && {storeId}),
      ...(category && {category}),
      ...(merchantAccountId && {merchantAccountId}),
      ...(batchId && {batchId}),
      ...(startDate && {
        transactionDateFrom: DateTime.fromJSDate(startDate).startOf('day').toJSDate(),
        transactionDateTo: endDate
          ? DateTime.fromJSDate(endDate).endOf('day').toJSDate()
          : DateTime.fromJSDate(startDate).endOf('day').toJSDate(),
      }),
    },
  };

  const accountActivityDetails = trpc.finance.getOrganizationAccountActivityDetails.useQuery(
    {
      ...accountActivityDetailsFilters,
      ...pagination,
    },
    {keepPreviousData: true}
  );

  const activeMerchantAccounts = useMemo(
    () => merchantAccounts?.filter(({status}) => status === 'ACTIVE') ?? [],
    [merchantAccounts]
  );
  const storeList = useMemo(() => {
    return (
      activeMerchantAccounts.find(merchant => merchant.id === merchantAccountId)?.stores ?? []
    ).map(store => ({
      id: store.id,
      name: store.displayName,
      status: store.status,
    }));
  }, [merchantAccountId, activeMerchantAccounts]);

  const handleDateChange = useCallback(
    (dateRange: {startDate: Date; endDate: Date}) => {
      setTypedSearchParams({batchId: undefined, ...dateRange});
    },
    [setTypedSearchParams]
  );

  const handleOpenSidePanel = useCallback(
    (row: OrganizationAccountActivityDetailsItem | TenantAccountActivityDetailsItem) => {
      setAccountActivityDetailsRow(row);
      openSidePanel();
    },
    [openSidePanel]
  );

  useEffect(() => {
    if (!storeList) {
      return;
    }
    if (!storeList.some(store => store.id === storeId)) {
      setTypedSearchParams({storeId: undefined});
    }
  }, [setTypedSearchParams, storeList, storeId]);

  const {showSuccessNotification, showErrorNotification} = useNotification();

  const trpcContext = trpc.useUtils();
  const {i18n} = useLingui();

  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullAccountActivityDetails = await fetchFullListFromPaginatedEndpoint(
        trpcContext.finance.getOrganizationAccountActivityDetails,
        accountActivityDetailsFilters
      );
      exportToCsvAccountActivityDetails(fullAccountActivityDetails.rows, i18n);
      showSuccessNotification(t`Account activity .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Account activity .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, accountActivityDetailsFilters, trpcContext, sort, typedSearchParams]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex flex-wrap items-center justify-between pt-4">
        <div className="flex gap-4 font-normal">
          <Typography variant="header-page">
            {startDate && endDate ? (
              <>
                <Trans>Account Activity For</Trans> {formatLocaleDate(startDate, 'short')}
                {' - '}
                {formatLocaleDate(endDate, 'short')}
              </>
            ) : (
              <Trans>Account Activity</Trans>
            )}
          </Typography>
        </div>
        <div className="flex items-center gap-4">
          <div className="hidden lg:block">
            <ActivityDetailsFilters
              typedSearchParams={typedSearchParams}
              setTypedSearchParams={setTypedSearchParams}
              dateValue={
                startDate
                  ? {
                      startDate,
                      endDate,
                    }
                  : null
              }
              category={category}
              categoryList={OrganizationCategoryArray}
              selectedMerchantAccount={merchantAccountId}
              merchantAccounts={merchantAccounts}
              storeList={storeList}
              storeId={storeId}
              handleDateChange={handleDateChange}
            />
          </div>
          <SlideOverWithBrandedHeader
            isOpen={isFiltersOpen}
            title={'Filters'}
            closeHandler={() => setIsFiltersOpen(false)}
            panelClassName="w-screen pointer-events-auto lg:max-w-md"
          >
            <ActivityDetailsFilters
              typedSearchParams={typedSearchParams}
              setTypedSearchParams={setTypedSearchParams}
              dateValue={
                startDate
                  ? {
                      startDate,
                      endDate,
                    }
                  : null
              }
              category={category}
              categoryList={OrganizationCategoryArray}
              storeList={storeList}
              storeId={storeId}
              selectedMerchantAccount={merchantAccountId}
              merchantAccounts={merchantAccounts}
              handleDateChange={handleDateChange}
            />
          </SlideOverWithBrandedHeader>
          <Button
            type="button"
            size="sm"
            onClick={() => setIsFiltersOpen(true)}
            className="lg:hidden w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
          >
            <Trans>Filter</Trans>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Button>
          <div className="lg:hidden">
            <ResetTableFiltersButton
              ignoreKeys={[
                'endDate', // ignore endDate to show date range as a single applied date filter
              ]}
              defaultFilters={{}}
              activeFilters={typedSearchParams}
              setFilters={setTypedSearchParams}
            />
          </div>
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit"
            isLoading={isCsvLoading}
            onClick={handleCsvExport}
            disabled={!accountActivityDetails.data || accountActivityDetails.data.rows.length === 0}
          >
            <Trans>Export to CSV</Trans>
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <AccountActivityDetailsTable
          accountActivityDetails={accountActivityDetails.data}
          filters={typedSearchParams}
          pagination={pagination}
          onPaginationChange={setPagination}
          onRowClick={handleOpenSidePanel}
          sort={sort}
          setSort={setSort}
          paymentDetailsPath={RoutePath.PAYMENT_DETAILS}
          activityDetailsPath={RoutePath.FINANCE_ACCOUNT_ACTIVITY}
          showMerchantColumn
          showStoreColumn
          isLoading={
            accountActivityDetails.isLoading ||
            (accountActivityDetails.isRefetching && accountActivityDetails.isPreviousData)
          }
        />
      </div>
      <AccountActivityDetailsSidePanel
        isOpen={isSidePanelOpen}
        onCancel={closeSidePanel}
        row={accountActivityDetailsRow}
        merchantDetailsPagePath={RoutePath.MERCHANT_ACCOUNT_DETAILS}
        paymentDetailsPath={RoutePath.PAYMENT_DETAILS}
        activityDetailsPath={RoutePath.FINANCE_ACCOUNT_ACTIVITY}
        showStore
      />
    </div>
  );
};
