import {t} from '@lingui/macro';
import {StepperVerticalItem} from '@zentact/ui-tailwind';

export const MERCHANT_REGISTRATION_STEP_IDS = {
  step1: 'step1-create-account',
  step2: 'step2-terms-and-conditions',
  step3: 'step3-merchant-application',
  step4: 'step4-merchant-verification',
  step5: 'step5-complete',
} as const;

export const merchantRegistrationStepTitles: Record<
  keyof typeof MERCHANT_REGISTRATION_STEP_IDS,
  string
> = {
  step1: t`Verify Email`,
  step2: t`Terms And Conditions`,
  step3: t`Merchant Application`,
  step4: t`Merchant Verification`,
  step5: t`Complete! Next Steps`,
};

export const merchantRegistrationSteps: StepperVerticalItem[] = [
  {
    id: MERCHANT_REGISTRATION_STEP_IDS.step1,
    title: merchantRegistrationStepTitles.step1,
  },
  {
    id: MERCHANT_REGISTRATION_STEP_IDS.step2,
    title: merchantRegistrationStepTitles.step2,
  },
  {
    id: MERCHANT_REGISTRATION_STEP_IDS.step3,
    title: merchantRegistrationStepTitles.step3,
  },
  {
    id: MERCHANT_REGISTRATION_STEP_IDS.step4,
    title: merchantRegistrationStepTitles.step4,
  },
  {
    id: MERCHANT_REGISTRATION_STEP_IDS.step5,
    title: merchantRegistrationStepTitles.step5,
  },
];

export const NAME_SURNAME_REGEX = /^[a-z ,.'-]+$/i;
