import {I18n} from '@lingui/core';
import {createColumnHelper} from '@tanstack/react-table';
import {createTRPCReact} from '@trpc/react-query';
import type {ServerRouter} from '@zentact/api';
import {TaxFormYearsOutput} from '@zentact/api/src/trpc/routers/financeRouter';
import {TableColumnSize} from '@zentact/common';
import {TruncatedText} from '../../../other';
import {TaxFormActions} from './tax-form-actions';

const columnsHelper = createColumnHelper<TaxFormYearsOutput['rows'][0]>();

type Props = {
  i18n: I18n;
  trpc: ReturnType<typeof createTRPCReact<ServerRouter>>;
};
export const getTaxFormColumns = ({i18n, trpc}: Props) => {
  return [
    columnsHelper.display({
      id: 'year',
      header: () => i18n._('Year'),
      cell: props => props.row.original.year,
      size: TableColumnSize.S,
    }),
    columnsHelper.accessor('merchantAccount.businessName', {
      cell: info => <TruncatedText text={info.getValue() || '-'} />,
      header: () => i18n._('Merchant Account'),
    }),
    columnsHelper.display({
      id: 'taxForm',
      cell: () => '1099-K',
      header: () => i18n._('Tax Form'),
    }),

    columnsHelper.display({
      id: 'actions',
      cell: props => {
        return <TaxFormActions taxFormYearRow={props.row.original} trpc={trpc} />;
      },
      size: TableColumnSize.ACTIONS,
    }),
  ];
};
