import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {DeclinedPaymentListItemOutput} from '@zentact/api/src/trpc/routers/paymentRouter';
import {declinedPaymentsSchema} from '@zentact/common/src/schemas/searchParamsSchemas';
import {
  Breadcrumbs,
  Button,
  DeclinedPaymentFilters,
  DeclinedPaymentsList,
  ResetTableFiltersButton,
  SelectInputFilter,
  SlideOverWithBrandedHeader,
  TableSort,
  TableSortValue,
  Typography,
  getDashboardDatepickerDefaults,
  useNotification,
  useToggle,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {DeclinedPaymentDetailsPanel} from '@zentact/ui-tailwind/layout/payments/details-panel/declined-payment-details-panel';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {exportToCsvDeclinedPayments} from './csv-export';

const getBreadCrumbs = () => [
  {name: t`Transactions`, href: RoutePath.TRANSACTIONS, current: false},
  {name: t`Declined Payments`, href: RoutePath.DECLINED_PAYMENTS, current: true},
];

export const DeclinedPayments = () => {
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {merchantAccounts, tenant} = useStore();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const defaultFilters = {
    pspReferenceId: undefined,
    pspMerchantAccountName: undefined,
    reference: undefined,
    paymentMethod: undefined,
    shopperEmail: undefined,
    selectedMerchantAccount: undefined,
    source: undefined,
    shopperId: undefined,
    refusalReason: undefined,
    storeId: undefined,
  };

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(declinedPaymentsSchema);

  const pspReferenceId = typedSearchParams?.pspReferenceId;
  const reference = typedSearchParams?.reference;
  const paymentMethod = typedSearchParams?.paymentMethod;
  const source = typedSearchParams?.source;
  const shopperEmail = typedSearchParams?.shopperEmail;
  const selectedMerchantAccount = typedSearchParams?.selectedMerchantAccount;
  const shopperId = typedSearchParams?.shopperId;
  const startDate = typedSearchParams?.startDate;
  const endDate = typedSearchParams?.endDate;
  const dateValue = startDate && endDate ? {startDate, endDate} : getDashboardDatepickerDefaults();
  const refusalReason = typedSearchParams?.refusalReason;
  const storeId = typedSearchParams?.storeId;

  const [sort, setSort] = useState<TableSortValue<string>>({columnId: 'createdAt', value: 'desc'});
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});

  const activeMerchantAccounts = useMemo(
    () => merchantAccounts?.filter(({status}) => status === 'ACTIVE') ?? [],
    [merchantAccounts]
  );
  const storeList = useMemo(() => {
    return (
      activeMerchantAccounts.find(merchant => merchant.id === selectedMerchantAccount)?.stores ?? []
    ).map(store => ({
      id: store.id,
      name: store.displayName,
      status: store.status,
    }));
  }, [selectedMerchantAccount, activeMerchantAccounts]);

  const {
    data: {filters: paymentMethodsFilters, filtersDescription: paymentMethodsFiltersDescription},
  } = trpc.payment.paymentMethods.useQuery(undefined, {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    initialData: {list: [], filters: [], filtersDescription: {}},
  });

  const paymentsFilters = {
    ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
    where: {
      ...(pspReferenceId && {pspReferenceId}),
      ...(reference && {reference}),
      ...(shopperId && {shopperId}),
      ...(storeId && {storeId}),
      ...(refusalReason && {refusalReason}),
      ...(dateValue && {
        fromDate: dateValue.startDate.toISOString(),
        toDate: dateValue.endDate.toISOString(),
      }),
      ...(paymentMethod?.length && {
        paymentMethod: paymentMethod.flatMap(
          // biome-ignore lint/style/noNonNullAssertion: TODO
          filter => paymentMethodsFiltersDescription[filter]!
        ),
      }),
      ...(source?.length && {source}),
      ...(shopperEmail && {shopperEmail: shopperEmail}),
      ...(selectedMerchantAccount && {
        merchantAccountId: selectedMerchantAccount,
      }),
    },
  };
  const paymentList = trpc.payment.declinedPayments.useQuery(
    {
      ...paymentsFilters,
      ...pagination,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
      onSuccess(data) {
        if (!paymentDetailsRow) {
          return;
        }

        const activePayment = data.rows.find(
          row => row.pspReferenceId === paymentDetailsRow.pspReferenceId
        );

        if (activePayment) {
          setPaymentDetailsRow(activePayment);
        }
      },
    }
  );
  const trpcContext = trpc.useUtils();

  const [isSidePanelOpen, openSidePanel, closeSidePanel] = useToggle(false);
  const [paymentDetailsRow, setPaymentDetailsRow] = useState<DeclinedPaymentListItemOutput | null>(
    null
  );

  const handleOpenSidePanel = useCallback(
    (row: DeclinedPaymentListItemOutput) => {
      setPaymentDetailsRow(row);
      openSidePanel();
    },
    [openSidePanel, setPaymentDetailsRow]
  );

  const {i18n} = useLingui();

  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullPaymentList = await trpcContext.payment.declinedPayments.fetch(paymentsFilters);
      exportToCsvDeclinedPayments(
        fullPaymentList,
        i18n,
        tenant?.checkoutConfiguration.customAttributesNames
      );
      showSuccessNotification(t`Declined Payments .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Declined Payments .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, paymentList.data, paymentsFilters, trpcContext]);

  // When filter or sorting changes we need to reset pagination
  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams, sort]);

  const nextSort: Record<string, TableSort | null> = {
    asc: null,
    desc: 'asc',
    null: 'desc',
  };

  const handleDateChange = (dateRange: {startDate: Date; endDate: Date}) => {
    setTypedSearchParams({
      ...dateRange,
    });
  };

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Declined Payments</Trans>
        </Typography>
        <Button
          type="button"
          variant="primary"
          size="md"
          className="w-fit max-sm:w-full"
          isLoading={isCsvLoading}
          onClick={handleCsvExport}
          disabled={!paymentList.data || paymentList.data.rows.length === 0}
        >
          <Trans>Export to CSV</Trans>
        </Button>
      </div>
      <div className="flex justify-between gap-2 mt-4 font-normal max-sm:flex-wrap sm:items-center">
        <div className="w-32">
          <SelectInputFilter
            options={[
              {
                id: 'createdAt',
                label: i18n._('Date'),
              },
              {
                id: 'amount',
                label: i18n._('Amount'),
              },
            ]}
            sortValue={sort}
            onChange={columnId => {
              setSort({
                columnId,
                value: nextSort[String(sort.value)] as TableSort | null,
              });
            }}
            placeholder={i18n._('Sort')}
          />
        </div>
        <div className="flex justify-between gap-2">
          <div className="lg:hidden">
            <ResetTableFiltersButton
              defaultFilters={defaultFilters}
              activeFilters={typedSearchParams}
              setFilters={setTypedSearchParams}
            />
          </div>
          <Button
            type="button"
            size="sm"
            onClick={() => setIsFiltersOpen(true)}
            className="lg:hidden w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
          >
            <Trans>Filter</Trans>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Button>
        </div>
        <div className="hidden lg:block">
          <DeclinedPaymentFilters
            defaultFilters={defaultFilters}
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
            selectedMerchantAccount={selectedMerchantAccount}
            merchantAccounts={merchantAccounts}
            paymentMethod={paymentMethod}
            reference={reference}
            pspReferenceId={pspReferenceId}
            storeList={storeList}
            storeId={storeId}
            source={source}
            dateValue={dateValue}
            refusalReason={refusalReason}
            handleDateChange={handleDateChange}
            paymentMethodsFilters={paymentMethodsFilters}
          />
        </div>
        <SlideOverWithBrandedHeader
          isOpen={isFiltersOpen}
          title={'Filters'}
          closeHandler={() => setIsFiltersOpen(false)}
          panelClassName="w-screen pointer-events-auto lg:max-w-md"
        >
          <DeclinedPaymentFilters
            defaultFilters={defaultFilters}
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
            selectedMerchantAccount={selectedMerchantAccount}
            merchantAccounts={merchantAccounts}
            paymentMethod={paymentMethod}
            reference={reference}
            pspReferenceId={pspReferenceId}
            storeList={storeList}
            storeId={storeId}
            source={source}
            dateValue={dateValue}
            refusalReason={refusalReason}
            handleDateChange={handleDateChange}
            paymentMethodsFilters={paymentMethodsFilters}
          />
        </SlideOverWithBrandedHeader>
      </div>
      <div className="mt-4">
        <DeclinedPaymentsList
          paymentsList={paymentList.data}
          filters={typedSearchParams}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          openDetailsPanel={handleOpenSidePanel}
          customAttributes={tenant?.checkoutConfiguration.customAttributesNames}
          isLoading={
            paymentList.isLoading || (paymentList.isRefetching && paymentList.isPreviousData)
          }
        />
      </div>
      <DeclinedPaymentDetailsPanel
        isOpen={isSidePanelOpen}
        onCancel={closeSidePanel}
        declinedPaymentRow={paymentDetailsRow}
      />
    </div>
  );
};
