import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {ChevronDownIcon} from '@heroicons/react/20/solid';
import {Trans, t} from '@lingui/macro';
import {StoresListItem} from '@zentact/api/src/trpc/routers/storeRouter';
import {storesFiltersSchema} from '@zentact/common';
import {
  Breadcrumbs,
  Button,
  ResetTableFiltersButton,
  SlideOverWithBrandedHeader,
  StoreFilters,
  TableSortValue,
  Typography,
  useToggle,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {useCallback, useEffect, useState} from 'react';
import {z} from 'zod';
import {StoreDetailsSidePanel} from './store-details';
import {StoresList} from './stores-list';

const breadcrumbs = () => [{name: t`Stores`, href: RoutePath.STORES, current: true}];

const storesSearchParamsSchema = z.intersection(
  storesFiltersSchema,
  z.object({
    filtersFormOpen: z
      .boolean()
      .or(z.string().transform(value => value === 'true'))
      .nullable()
      .optional(),
  })
);

export const Stores = () => {
  const {activeAndInactiveMerchantAccounts: merchantAccounts, organization} = useStore();
  const {typedSearchParams: typedSearchParamsAll, setTypedSearchParams} =
    useTypedSearchParams(storesSearchParamsSchema);
  const {filtersFormOpen, ...typedSearchParams} = typedSearchParamsAll;
  const {selectedMerchantAccount, displayName, statuses} = typedSearchParams;
  const organizationId = organization?.id;
  const [isSidePanelOpen, openSidePanel, closeSidePanel] = useToggle(false);
  const [storeDetailsRow, setStoreDetailsRow] = useState<StoresListItem | null>(null);
  const [sort, setSort] = useState<TableSortValue<string> | null>(null);
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});

  const storesList = trpc.store.getStoresList.useQuery(
    {
      ...pagination,
      ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
      where: {
        organizationId,
        ...(statuses?.length && {statuses}),
        ...(selectedMerchantAccount && {selectedMerchantAccount}),
        ...(displayName && {displayName}),
      },
    },
    {
      refetchOnWindowFocus: true,
      refetchInterval: 5000,
    }
  );

  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [statuses, organizationId, selectedMerchantAccount, sort]);

  const handleSelectStore = useCallback((row: StoresListItem) => {
    setStoreDetailsRow(row);
    openSidePanel();
  }, []);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={breadcrumbs()} />
      <div className="flex justify-between gap-2 py-4 max-sm:flex-col sm:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Stores</Trans>
        </Typography>
      </div>
      <div className="flex justify-between gap-2 font-normal max-sm:flex-wrap sm:items-center">
        <div className="flex justify-between gap-2">
          <div className="lg:hidden">
            <ResetTableFiltersButton
              defaultFilters={{}}
              activeFilters={typedSearchParams}
              setFilters={setTypedSearchParams}
            />
          </div>
          <Button
            type="button"
            size="sm"
            onClick={() => setTypedSearchParams({filtersFormOpen: true})}
            className="lg:hidden w-fit relative min-h-[2.25rem] shadow-none cursor-pointer font-semibold rounded-md py-1.5 pl-3 pr-10 text-left focus:outline-none text-gray-700 disabled:bg-slate-100 bg-transparent hover:bg-transparent focus:bg-transparent active:bg-transparent sm:text-sm sm:leading-6 border-none"
          >
            <Trans>Filter</Trans>
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
              <ChevronDownIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            </span>
          </Button>
        </div>
        <div className="hidden lg:block">
          <StoreFilters
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
            organizationId={organizationId}
            statuses={statuses}
            selectedMerchantAccount={selectedMerchantAccount}
            merchantAccounts={merchantAccounts}
            displayName={displayName}
          />
        </div>
        <SlideOverWithBrandedHeader
          isOpen={!!filtersFormOpen}
          title={'Filters'}
          closeHandler={() => setTypedSearchParams({filtersFormOpen: false})}
          panelClassName="w-screen pointer-events-auto lg:max-w-md"
        >
          <StoreFilters
            typedSearchParams={typedSearchParams}
            setTypedSearchParams={setTypedSearchParams}
            organizationId={organizationId}
            statuses={statuses}
            selectedMerchantAccount={selectedMerchantAccount}
            merchantAccounts={merchantAccounts}
            displayName={displayName}
          />
        </SlideOverWithBrandedHeader>
      </div>
      <div className="mt-4">
        <StoresList
          storesList={storesList.data}
          filters={typedSearchParams || {}}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          openDetailsPanel={handleSelectStore}
          isLoading={storesList.isLoading}
        />
      </div>
      <StoreDetailsSidePanel
        isOpen={isSidePanelOpen}
        onCancel={closeSidePanel}
        row={storeDetailsRow}
        merchantDetailsPagePath={RoutePath.MERCHANT_ACCOUNT_DETAILS}
      />
    </div>
  );
};
