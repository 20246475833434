import {Trans} from '@lingui/macro';
import {GetCheckoutV2Output} from '@zentact/api/src/trpc/routers/checkoutRouter';
import {
  AchIcon,
  ApplePayIcon,
  InputRadio,
  Typography,
  VisaMastercardDiscoverAmexIcon,
} from '@zentact/ui-tailwind';
import {useMemo} from 'react';
import {UseFormReturn} from 'react-hook-form';
import {CheckoutV2FormData} from './checkout-schema';

type PaymentMethodSelectorProps = {
  form: UseFormReturn<CheckoutV2FormData>;
  checkout: GetCheckoutV2Output;
};

export const PaymentMethodSelector = ({form, checkout}: PaymentMethodSelectorProps) => {
  const {register, watch} = form;
  const type = watch('paymentMethod.type');
  const availablePaymentMethods = useMemo(() => {
    // for now only scheme is supported to save payment methods
    if (checkout?.savePaymentMethod === 'ADD_EMBED') {
      return {
        ach: null,
        applepay: null,
        scheme: checkout?.availablePaymentMethods.scheme,
      };
    }
    return checkout.availablePaymentMethods;
  }, [checkout]);

  return (
    <div className="flex flex-col gap-2 sm:col-span-4">
      <Typography variant="header-form-section">
        <div className="text-primary-600">
          <Trans>Payment Method</Trans>
        </div>
      </Typography>
      <div className="flex flex-wrap gap-2">
        {!!checkout.savedPaymentMethods?.length && checkout.merchantShopperId && (
          <InputRadio
            {...register('paymentMethod.type')}
            value="savedPaymentMethod"
            inputClassName="invisible absolute -left-full"
            labelClassName="ms-0"
            label={
              <div
                className={`border w-20 h-16 text-primary-600 text-center flex items-center justify-center ${
                  type === 'savedPaymentMethod' ? 'border-primary-600' : 'border-gray-300'
                } rounded-lg`}
              >
                <Trans>Saved Methods</Trans>
              </div>
            }
          />
        )}
        {!!availablePaymentMethods.scheme && (
          <InputRadio
            {...register('paymentMethod.type')}
            value="scheme"
            inputClassName="invisible absolute -left-full"
            labelClassName="ms-0"
            label={
              <div
                className={`border px-2 ${
                  type === 'scheme' ? 'border-primary-600' : 'border-gray-300'
                } rounded-lg`}
              >
                <VisaMastercardDiscoverAmexIcon />
              </div>
            }
          />
        )}
        {!!availablePaymentMethods.ach && (
          <InputRadio
            {...register('paymentMethod.type')}
            inputClassName="invisible absolute -left-full"
            labelClassName="ms-0"
            value="ach"
            label={
              <div
                className={`border px-2 ${
                  type === 'ach' ? 'border-primary-600' : 'border-gray-300'
                } rounded-lg`}
              >
                <AchIcon />
              </div>
            }
          />
        )}
        {!!availablePaymentMethods.applepay && !!window.ApplePaySession && (
          <div className="flex flex-row items-center gap-x-3 gap-y-4">
            <InputRadio
              {...register('paymentMethod.type')}
              inputClassName="invisible absolute -left-full"
              labelClassName="ms-0"
              value="applepay"
              label={
                <div
                  className={`border px-2 ${
                    type === 'applepay' ? 'border-blue-500' : 'border-gray-300'
                  } rounded-lg`}
                >
                  <ApplePayIcon />
                </div>
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
