import {trpc} from '@/api/trpcClient';
import {useStore} from '@/store';
import {Trans} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {Button, SlideOverWithBrandedHeader, useNotification} from '@zentact/ui-tailwind';
import {useEffect} from 'react';

import type {PayoutDetailsSchema} from '@adyen/kyc-components/dist/types/components/PayoutDetails/types';
import type {UIElement} from '@adyen/kyc-components/dist/types/components/UIElement/UIElement';
import type {TokenResponse} from '@adyen/kyc-components/dist/types/core/context/AuthContext/AuthProvider';
import type {CountryCode} from '@adyen/kyc-components/dist/types/core/models/country-code';
import {loadAdyenKyc} from './loadAdyenKyc';

export type UpdateBankAccountProps = {
  merchantAccountId: string;
  transferInstruments: {
    id: string;
    bankAccountLast4: string | null;
    bankAccountType: string | null;
  }[];
  countryCode: string | null;
  legalEntityId: string | null;
  onClose: () => void;
  isOpen: boolean;
  triggerRefetch: () => void;
};

export const UpdateBankAccount = ({
  merchantAccountId,
  transferInstruments,
  countryCode,
  legalEntityId,
  onClose,
  isOpen,
  triggerRefetch: refetchMerchantAccountsList,
}: UpdateBankAccountProps) => {
  const {i18n} = useLingui();
  const trpcContext = trpc.useUtils();
  const {tenant, locale} = useStore();

  const {showSuccessNotification} = useNotification();

  const triggerMerchantTransferInstrmuentsSync =
    trpc.transferInstrument.triggerMerchantTransferInstrumentsSync.useMutation();

  const onTransferInstrumentUpdatedSuccess = () => {
    triggerMerchantTransferInstrmuentsSync.mutate({merchantAccountId});
    refetchMerchantAccountsList();
    onClose();
    showSuccessNotification('Transfer Instrument updated');
  };

  const session = trpc.transferInstrument.createTransferInstrumentSessionToken.useQuery(
    {merchantAccountId},
    {enabled: !!legalEntityId}
  );

  async function getSdkToken(): Promise<TokenResponse> {
    try {
      const data = await trpcContext.transferInstrument.createTransferInstrumentSessionToken.fetch({
        merchantAccountId,
      });
      if (!data.token) {
        throw new Error('Response is missing the required token field');
      }
      return data;
    } catch (error) {
      throw new Error(`Failed to fetch SDK token: ${(error as Error).message}`);
    }
  }

  useEffect(() => {
    if (!session.data || !legalEntityId || !isOpen) return;

    let manageTransferInstrumentComponent: UIElement | undefined;
    let editTransferInstrumentComponent: UIElement | undefined;

    (async () => {
      // Lazy-load the CSS and the module
      const adyenKyc = await loadAdyenKyc();

      const adyenKycHandler = new adyenKyc({
        locale,
        country: countryCode as CountryCode,
        environment: session.data.environment,
        sdkToken: session.data.token,
        getSdkToken,
      });

      if (!tenant?.features?.enableMultiStore) {
        editTransferInstrumentComponent = adyenKycHandler
          .create('createTransferInstrumentComponent', {
            legalEntityId,
            transferInstrumentId: transferInstruments[0]?.id,
            // biome-ignore lint/correctness/noUnusedVariables: <explanation>
            onSubmitSuccess: async (data: PayoutDetailsSchema) => {
              onTransferInstrumentUpdatedSuccess();
              manageTransferInstrumentComponent?.unmount();
            },
            onClose,
            modalView: true,
          })
          .mount('#edit-transfer-instrument-container');
        return;
      }

      manageTransferInstrumentComponent = adyenKycHandler
        .create('manageTransferInstrumentComponent', {
          legalEntityId,
          onAdd: (legalEntityId: string) => {
            if (manageTransferInstrumentComponent) {
              manageTransferInstrumentComponent.remount();
            }
            if (editTransferInstrumentComponent) {
              editTransferInstrumentComponent.unmount();
            }

            editTransferInstrumentComponent = adyenKycHandler
              .create('createTransferInstrumentComponent', {
                legalEntityId,
                // biome-ignore lint/correctness/noUnusedVariables: <explanation>
                onSubmitSuccess: async (data: PayoutDetailsSchema) => {
                  onTransferInstrumentUpdatedSuccess();
                  manageTransferInstrumentComponent?.unmount();
                },
                modalView: true,
              })
              .mount('#edit-transfer-instrument-container');
          },
          onEdit: (transferInstrumentId: string, legalEntityId: string) => {
            if (manageTransferInstrumentComponent) {
              manageTransferInstrumentComponent.remount();
            }

            if (editTransferInstrumentComponent) {
              editTransferInstrumentComponent.unmount();
            }

            editTransferInstrumentComponent = adyenKycHandler
              .create('createTransferInstrumentComponent', {
                legalEntityId,
                transferInstrumentId,
                modalView: true,
                // biome-ignore lint/correctness/noUnusedVariables: <explanation>
                onSubmitSuccess: async (data: PayoutDetailsSchema) => {
                  onTransferInstrumentUpdatedSuccess();
                  manageTransferInstrumentComponent?.unmount();
                },
              })
              .mount('#edit-transfer-instrument-container');
          },
          // biome-ignore lint/correctness/noUnusedVariables: <explanation>
          onRemoveSuccess: async (transferInstrumentId: string, legalEntityId: string) => {
            onTransferInstrumentUpdatedSuccess();
            manageTransferInstrumentComponent?.unmount();
          },
        })
        .mount('#manage-transfer-instrument-container');
    })();

    return () => {
      if (manageTransferInstrumentComponent) {
        manageTransferInstrumentComponent.unmount();
      }
    };
  }, [isOpen, session.data, legalEntityId]);

  return tenant?.features?.enableMultiStore ? (
    <SlideOverWithBrandedHeader
      isOpen={isOpen}
      panelClassName="w-screen pointer-events-auto md:max-w-lg"
      title={i18n._('Manage Bank Accounts')}
      text={
        tenant?.features?.enableMultiStore
          ? i18n._('Add, edit, or remove bank accounts linked to your merchant account')
          : i18n._(
              'Update your existing bank account. This will change the bank account for payouts assigned to your merchant'
            )
      }
      closeHandler={onClose}
      footer={
        <footer className="flex flex-row-reverse p-4 shrink-0 gap-x-3">
          <Button variant="secondary" size="lg" className="w-fit" onClick={onClose}>
            <Trans>Close</Trans>
          </Button>
        </footer>
      }
    >
      <>
        <div id="manage-transfer-instrument-container" />
        <div id="edit-transfer-instrument-container" />
      </>
    </SlideOverWithBrandedHeader>
  ) : (
    <div id="edit-transfer-instrument-container" className="invisible" />
  );
};
