import {trpc} from '@/api/trpcClient';
import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {useLingui} from '@lingui/react';
import {ChargebackListItemOutput} from '@zentact/api/src/trpc/routers/chargebackRouter';
import {
  Breadcrumbs,
  Button,
  ChargebackDetailsPanel,
  EntityPicker,
  MerchantAccountsPicker,
  ResetTableFiltersButton,
  TableSortValue,
  Typography,
  displayChargebackTypeMap,
  fetchFullListFromPaginatedEndpoint,
  useNotification,
  useToggle,
  useTypedSearchParams,
} from '@zentact/ui-tailwind';
import {ChargebackList} from '@zentact/ui-tailwind/layout/payments/chargeback-list/chargeback-list';
import {DateTime} from 'luxon';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {z} from 'zod';
import {exportToCsvDisputes} from './csv-export';

const getBreadCrumbs = () => [
  {name: t`Transactions`, href: RoutePath.TRANSACTIONS, current: false},
  {name: t`Disputes`, href: RoutePath.DISPUTES, current: true},
];

export const Disputes = () => {
  const {i18n} = useLingui();
  const {showSuccessNotification, showErrorNotification} = useNotification();
  const {activeAndInactiveMerchantAccounts: merchantAccounts} = useStore();

  const disputesSchema = z.object({
    type: z
      .array(z.string())
      .or(z.string().transform(str => str.split(',')))
      .optional(),
    status: z
      .array(z.string())
      .or(z.string().transform(str => str.split(',')))
      .optional(),
    startDate: z
      .date()
      .or(z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').toJSDate()))
      .nullable()
      .optional(),
    endDate: z
      .date()
      .or(
        z.string().transform(value => DateTime.fromFormat(value, 'y-MM-dd').endOf('day').toJSDate())
      )
      .nullable()
      .optional(),
    pspReferenceId: z.string().optional(),
    storeId: z.string().optional(),
    paymentPspReferenceId: z.string().optional(),
    paymentMethod: z
      .array(z.string())
      .or(z.string().transform(str => str.split(',')))
      .optional(),
    shopperEmail: z.string().email().optional(),
    selectedMerchantAccount: z.string().optional(),
  });

  const {typedSearchParams, setTypedSearchParams} = useTypedSearchParams(disputesSchema);

  const type = typedSearchParams?.type;
  const status = typedSearchParams?.status;
  const pspReferenceId = typedSearchParams?.pspReferenceId;
  const paymentPspReferenceId = typedSearchParams?.paymentPspReferenceId;
  const paymentMethod = typedSearchParams?.paymentMethod;
  const shopperEmail = typedSearchParams?.shopperEmail;
  const storeId = typedSearchParams?.storeId;
  const selectedMerchantAccount = typedSearchParams?.selectedMerchantAccount;
  const startDate = typedSearchParams?.startDate;
  const endDate = typedSearchParams?.endDate;

  const activeMerchantAccounts = useMemo(
    () => merchantAccounts?.filter(({status}) => status === 'ACTIVE') ?? [],
    [merchantAccounts]
  );
  const storeList = useMemo(() => {
    return (
      activeMerchantAccounts.find(merchant => merchant.id === selectedMerchantAccount)?.stores ?? []
    ).map(store => ({
      id: store.id,
      name: store.displayName,
      status: store.status,
    }));
  }, [selectedMerchantAccount, activeMerchantAccounts]);

  const [sort, setSort] = useState<TableSortValue<string>>({columnId: 'createdAt', value: 'desc'});
  const [pagination, setPagination] = useState({pageIndex: 0, pageSize: 25});

  const {
    data: {filtersDescription: paymentMethodsFiltersDescription},
  } = trpc.payment.paymentMethods.useQuery(undefined, {
    keepPreviousData: true,
    refetchOnWindowFocus: true,
    initialData: {list: [], filters: [], filtersDescription: {}},
  });

  const chargebackFilters = {
    ...(sort?.columnId && sort.value && {orderBy: {[sort.columnId]: sort.value}}),
    where: {
      ...(type?.length && {type}),
      ...(startDate && {startDate: startDate.toISOString()}),
      ...(endDate && {endDate: endDate.toISOString()}),
      ...(status?.length && {status}),
      ...(storeId && {storeId}),
      ...(pspReferenceId && {pspReferenceId}),
      ...(paymentPspReferenceId && {paymentPspReferenceId}),
      ...(paymentMethod?.length && {
        paymentMethod: paymentMethod.flatMap(
          // biome-ignore lint/style/noNonNullAssertion: TODO
          filter => paymentMethodsFiltersDescription[filter]!
        ),
      }),
      ...(shopperEmail && {shopperEmail}),
      ...(selectedMerchantAccount && {
        merchantAccountId: selectedMerchantAccount,
      }),
    },
  };
  const chargebackList = trpc.chargeback.chargebackList.useQuery(
    {
      ...chargebackFilters,
      ...pagination,
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: true,
    }
  );
  const trpcContext = trpc.useUtils();

  const [isSidePanelOpen, openSidePanel, closeSidePanel] = useToggle(false);
  const [chargebackDetailsRow, setChargebackDetailsRow] = useState<ChargebackListItemOutput | null>(
    null
  );

  const handleOpenSidePanel = useCallback(
    (row: ChargebackListItemOutput) => {
      setChargebackDetailsRow(row);
      openSidePanel();
    },
    [openSidePanel, setChargebackDetailsRow]
  );

  const [isCsvLoading, setCsvLoading] = useState(false);
  const handleCsvExport = useCallback(async () => {
    setCsvLoading(true);
    try {
      const fullDisputeList = await fetchFullListFromPaginatedEndpoint(
        trpcContext.chargeback.chargebackList,
        chargebackFilters
      );
      exportToCsvDisputes(fullDisputeList.rows, i18n);
      showSuccessNotification(t`Disputes .csv file exported`);
    } catch (e) {
      showErrorNotification(t`Disputes .csv export failed`, (e as Error).message);
    }
    setCsvLoading(false);
  }, [setCsvLoading, chargebackFilters, trpcContext, exportToCsvDisputes]);

  // When filter or sorting changes we need to reset pagination
  useEffect(() => {
    setPagination(prev => ({...prev, pageIndex: 0}));
  }, [typedSearchParams, sort]);

  const handleMerchantAccountSelect = useCallback(
    (selectedMerchantAccount?: string) => {
      setTypedSearchParams({selectedMerchantAccount});
    },
    [setTypedSearchParams]
  );
  const handleDisputeTypeSelect = useCallback(
    (selectedType?: string) => {
      setTypedSearchParams(selectedType ? {type: [selectedType]} : {type: []});
    },
    [setTypedSearchParams]
  );
  const handleStoreSelect = useCallback(
    (storeId?: string) => {
      setTypedSearchParams({storeId});
    },
    [setTypedSearchParams]
  );

  useEffect(() => {
    if (!storeList) {
      return;
    }
    if (!storeList.some(store => store.id === storeId)) {
      setTypedSearchParams({storeId: undefined});
    }
  }, [setTypedSearchParams, storeList, storeId]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-md:flex-col md:items-center">
        <Typography variant="header-page" className="flex">
          <Trans>Disputes</Trans>
        </Typography>
        <div className="flex gap-2 font-normal max-sm:flex-wrap sm:items-center">
          <div className="max-sm:w-full">
            <ResetTableFiltersButton
              defaultFilters={{}}
              activeFilters={typedSearchParams}
              setFilters={setTypedSearchParams}
            />
          </div>
          <div className="font-normal max-md:w-full shrink-0">
            <EntityPicker
              selected={type?.[0]}
              onChange={handleDisputeTypeSelect}
              options={Object.entries(displayChargebackTypeMap).map(([id, name]) => ({
                id: id as keyof typeof displayChargebackTypeMap,
                name,
              }))}
              label={t`All Dispute Types`}
            />
          </div>
          {!!merchantAccounts?.length && (
            <div className="font-normal shrink-0 w-60 max-sm:w-full">
              <MerchantAccountsPicker
                selectedMerchantAccount={selectedMerchantAccount}
                onSelectMerchantAccount={handleMerchantAccountSelect}
                merchantAccountsOptions={merchantAccounts}
                allLabel={t`All Merchant Accounts`}
              />
            </div>
          )}
          <div className="font-normal shrink-0 max-lg:w-full">
            {!!storeList?.length && (
              <div className="font-normal shrink-0 max-lg:w-full">
                <EntityPicker
                  selected={storeId}
                  onChange={handleStoreSelect}
                  options={storeList
                    .sort((a, b) => (a.status === 'ACTIVE' ? -1 : b.status === 'ACTIVE' ? 1 : 0))
                    .map(store => ({
                      name: store.name,
                      id: store.id,
                      ...(store.status !== 'ACTIVE' && {
                        className: 'text-gray-400',
                        classNameActive: 'text-gray-400',
                        tooltipText: i18n._('Store is not active'),
                      }),
                    }))}
                  label={t`All Stores`}
                />
              </div>
            )}
          </div>
          <Button
            type="button"
            variant="primary"
            size="md"
            className="w-fit max-sm:w-full"
            isLoading={isCsvLoading}
            onClick={handleCsvExport}
            disabled={!chargebackList.data || chargebackList.data.rows.length === 0}
          >
            <Trans>Export to CSV</Trans>
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <ChargebackList
          chargebackList={chargebackList.data}
          filters={typedSearchParams}
          setFilters={setTypedSearchParams}
          sort={sort}
          setSort={setSort}
          pagination={pagination}
          onPaginationChange={setPagination}
          openDetailsPanel={handleOpenSidePanel}
          isLoading={
            chargebackList.isLoading ||
            (chargebackList.isRefetching && chargebackList.isPreviousData)
          }
        />
      </div>
      <ChargebackDetailsPanel
        isOpen={isSidePanelOpen}
        onCancel={closeSidePanel}
        chargebackRow={chargebackDetailsRow}
        trpc={trpc}
      />
    </div>
  );
};
