import {RoutePath} from '@/components/layout/navigation';
import {useStore} from '@/store';
import {Trans, t} from '@lingui/macro';
import {Breadcrumbs, MerchantAccountsPicker, Typography} from '@zentact/ui-tailwind';
import {useEffect, useMemo, useState} from 'react';
import {CapitalOverviewComponent} from './capital-overview';

const getBreadCrumbs = () => [{name: t`Capital`, href: RoutePath.CAPITAL, current: true}];

export const Capital = () => {
  const {activeAndInactiveMerchantAccounts} = useStore();
  const activeMerchantAccounts = useMemo(
    () => activeAndInactiveMerchantAccounts?.filter(({status}) => status === 'ACTIVE') ?? [],
    [activeAndInactiveMerchantAccounts]
  );
  const [merchantAccountId, setMerchantAccountId] = useState(
    activeMerchantAccounts.length > 0 ? activeMerchantAccounts[0]?.id : ''
  );

  useEffect(() => {
    if (!merchantAccountId && activeMerchantAccounts.length > 0 && activeMerchantAccounts[0]) {
      setMerchantAccountId(activeMerchantAccounts[0].id);
    }
  }, [merchantAccountId, activeMerchantAccounts]);

  return (
    <div className="flex flex-col">
      <Breadcrumbs pages={getBreadCrumbs()} />
      <div className="flex justify-between gap-2 pt-4 max-2xl:flex-wrap 2xl:items-center">
        <Typography variant="header-page" className="flex-1">
          <Trans>Capital Overview</Trans>
          {` - ${activeMerchantAccounts.find(({id}) => id === merchantAccountId)?.businessName}`}
        </Typography>
        <div className="font-normal shrink-0 w-60 max-md:w-full">
          <MerchantAccountsPicker
            selectedMerchantAccount={merchantAccountId}
            onSelectMerchantAccount={setMerchantAccountId}
            merchantAccountsOptions={activeMerchantAccounts}
          />
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <CapitalOverviewComponent merchantAccountId={merchantAccountId} />
      </div>
    </div>
  );
};
